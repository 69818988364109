import VisibilitySensor from 'react-visibility-sensor';
import dynamic from 'next/dynamic';
const CountUp = dynamic(() => import('react-countup'), { ssr: false });

import { cn } from '@ui/helpers/utils';
import useWindowDimensions from 'hooks/useWindowDimensions';

import Text from '@atoms/Text';

const StatItems = ({ stats, isHomepage }) => {
    const { width } = useWindowDimensions();
    return (
        <VisibilitySensor partialVisibility>
            {({ isVisible }) => (
                <ul className="grid grid-cols-4 gap-6 md:flex md:gap-0">
                    {stats.map((data, index) => {
                        return (
                            <li
                                key={index}
                                className={cn(
                                    `w-full xl:my-2 col-span-2 md:col-span-1 relative md:px-1 xl:px-2 text-basicWhite flex justify-between md:justify-start `,
                                    {
                                        'md:order-none order-1': index === 1,
                                        'md:order-none order-2': index === 3,
                                    }
                                )}
                            >
                                <div className="w-full md:max-w-min">
                                    <Text
                                        className={cn(
                                            'block p6-regular md:p5-regular xl:p4-regular text-basicWhite whitespace-nowrap',
                                            {
                                                'text-gray-700': isHomepage,
                                            }
                                        )}
                                        content={data.title}
                                        isInnerHtml={true}
                                        htmlTag='h3'
                                    />
                                    <div className="flex items-center md:flex-col md:items-start">
                                        <span
                                            className={cn(
                                                `flex items-end  text-secondary-100 h2-semibold mt-0 relative ml-0`,
                                                {
                                                    'items-start':
                                                        data.suffix ==
                                                        'rupeeSymbol',
                                                    'text-primary-500':
                                                        isHomepage,
                                                }
                                            )}
                                        >
                                            {data.suffix == 'rupeeSymbol' ? (
                                                <div
                                                    className={cn(
                                                        'relative text-basicWhite top-[6px] md:top-[10px] -left-0 mr-1 md:mr-2 p6-medium md:p4-medium',
                                                        {
                                                            'text-basicBlack':
                                                                isHomepage,
                                                        }
                                                    )}
                                                >
                                                    ₹
                                                </div>
                                            ) : null}
                                            {isVisible ? (
                                                <CountUp
                                                    duration={data.duration}
                                                    decimals={
                                                        data.decimalPlaces
                                                    }
                                                    delay={data.delay}
                                                    start={0}
                                                    end={data.amount}
                                                    suffix={data.countSuffix}
                                                    className="h4-semibold md:h2-semibold"
                                                />
                                            ) : null}
                                            {data.suffix ==
                                            'rupeeSymbol' ? null : (
                                                <div className="relative -top-[10px] md:-top-[6px] left-1 p6-medium md:p4-medium">
                                                    %
                                                </div>
                                            )}
                                        </span>
                                        <div
                                            className={`md:block self-end md:self-auto pb-1 md:pl-0 md:pb-0 ${
                                                data.suffix === 'rupeeSymbol'
                                                    ? 'pl-2'
                                                    : 'pl-2'
                                            }`}
                                        >
                                            {data?.tagline ? (
                                                <Text
                                                    content={data.tagline}
                                                    className={cn(
                                                        `p6-regular md:p5-medium xl:p4-medium md:ml-5 text-gray-300 md:text-gray-50 md:-mt-1`,
                                                        {
                                                            'text-gray-700 md:text-gray-700':
                                                                isHomepage,
                                                        }
                                                    )}
                                                />
                                            ) : null}
                                            {data?.category ? (
                                                <Text
                                                    content={
                                                        width >= 768
                                                            ? data.category
                                                            : data.categoryShortName
                                                    }
                                                    className={cn(
                                                        `block p6-regular md:p5-medium xl:p4-medium text-gray-300 md:text-gray-50 md:mb-1 md:ml-0 md:-mt-1`,
                                                        {
                                                            'text-gray-700 md:text-gray-700':
                                                                isHomepage,
                                                        }
                                                    )}
                                                />
                                            ) : null}
                                        </div>
                                    </div>
                                </div>

                                {stats?.length - 1 !== index ? (
                                    <div
                                        className={cn(
                                            'h-[90%] relative mx-auto right-0 md:block hidden w-[1px] border-l border-primary-200 self-center',
                                            {
                                                'border-primary-100 backdrop-blur-md':
                                                    isHomepage,
                                            }
                                        )}
                                    ></div>
                                ) : null}
                            </li>
                        );
                    })}
                </ul>
            )}
        </VisibilitySensor>
    );
};
export default StatItems;
